import { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';

const Dashboard = lazy(() => import('../pages/lms/dashboard/Dashboard').then(({ Dashboard }) => ({ default: Dashboard })))
const Report = lazy(() => import('../pages/lms/report/Report').then(({ Report }) => ({ default: Report })))
const MyCourses = lazy(() => import('../pages/lms/course/my_courses/MyCourses').then(({ MyCourses }) => ({ default: MyCourses })))
const CourseMain = lazy(() => import('../pages/lms/course/CourseMain').then(({ CourseMain }) => ({ default: CourseMain })))
const PivotDetail = lazy(() => import('../pages/lms/course/detail/PivotDetail').then(({ PivotDetail }) => ({ default: PivotDetail })))
const PivotCourses = lazy(() => import('../pages/lms/course/sub_courses/PivotCourses').then(({ PivotCourses }) => ({ default: PivotCourses })))

export const lmsRoutes = () => {
  return (
    <Route path='/lms' element={<Outlet />}>
      <Route path="/lms/dashboard" element={(<Dashboard />)} />
      <Route path="/lms/report" element={(<Report />)} />
      <Route path="/lms/my-courses" element={(<MyCourses />)} />

      <Route path="/lms/courses" element={(<CourseMain />)} />
      <Route path="/lms/courses/:id" element={(<PivotCourses />)} />
      <Route path="/lms/courses/:id/create" element={(<PivotCourses />)} />

      <Route path="/lms/course/:id/overview" element={(<PivotDetail />)} />
      <Route path="/lms/course/:id/learn" element={(<PivotDetail />)} />
    </Route>
  );
}
