import { Navigate, Route, Routes } from 'react-router-dom';
import './css/style.css';
import { FormLogin } from './pages/auth/FormLogin';
import RequireNotAuth from './components/RequireNotAuth';
import MasterLayout from './components/MasterLayout';
import RequireAuth from './components/RequireAuth';
// import { Dashboard } from './pages/Dashboard';
import routes from './config/routes';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AuthInit from './context/AuthInit';
import { Logout } from './components/Logout';
import { lmsRoutes } from './config/lmsRoutes';
// import RequireRole from './components/RequireRole';
// import { useAuth } from './utils/Auth';
// import axios from "./config/axiosInterceptor";

function App() {
  return (
    <>
      <AuthInit>
        <Routes>
          <Route element={<RequireAuth />}>
            <Route element={<MasterLayout />}>
              {/* <Route index element={(<Dashboard />)} /> */}
              <Route path={'/logout'} element={<Logout />} />
              {routes.map((r, index) => (
                <Route
                  key={index}
                  path={r.path}
                  // exact={r.exact}
                  element={(r.element)}
                />
              ))}
              {lmsRoutes()}
              <Route
                path="lms/*"
                element={<Navigate to="/lms/courses" replace />}
              />
              <Route
                path="*"
                element={<Navigate to="/profil" replace />}
              />
            </Route>
          </Route>
          <Route element={<RequireNotAuth />}>
            <Route path="/login" element={(<FormLogin />)} />
            <Route
              path="*"
              element={<Navigate to="/login" replace />}
            />
          </Route>
        </Routes>
      </AuthInit>
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
