import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './context/AuthProvider';
import { ToastContainer } from 'react-toastify'
import DelayedFallback from './assets/DelayedFallback';
import * as serviceWorker from './serviceWorkerRegistration'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<DelayedFallback />}>
      <BrowserRouter basename='/'>
        <AuthProvider>
          <App />
          <ToastContainer autoClose={2000} className="toast-message"/>
        </AuthProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);
serviceWorker.register();
// serviceWorker.refresh();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
