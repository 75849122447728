import { lazy } from 'react';

// PAGE
const Beranda = lazy(() => import('../pages/beranda/Beranda').then(({ Beranda }) => ({ default: Beranda })))
const Diklat = lazy(() => import('../pages/diklat/Diklat').then(({ Diklat }) => ({ default: Diklat })))
const DiklatDetail = lazy(() => import('../pages/diklat_detail/DiklatDetail').then(({ DiklatDetail }) => ({ default: DiklatDetail })))
const Agenda = lazy(() => import('../pages/agenda/Agenda').then(({ Agenda }) => ({ default: Agenda })))
const Pengetahuan = lazy(() => import('../pages/pengetahuan/Pengetahuan').then(({ Pengetahuan }) => ({ default: Pengetahuan })))
const Forum = lazy(() => import('../pages/forum/Forum').then(({ Forum }) => ({ default: Forum })))
const Profil = lazy(() => import('../pages/profil/Profil').then(({ Profil }) => ({ default: Profil })))
const JadwalDiklat = lazy(() => import('../pages/jadwal_diklat/JadwalDiklat').then(({ JadwalDiklat }) => ({ default: JadwalDiklat })))
const AktivitasMateri = lazy(() => import('../pages/jadwal_diklat/materi/AktivitasMateri').then(({ AktivitasMateri }) => ({ default: AktivitasMateri })))
const AktivitasUjian = lazy(() => import('../pages/jadwal_diklat/ujian/AktivitasUjian').then(({ AktivitasUjian }) => ({ default: AktivitasUjian })))
const UjianWrapper = lazy(() => import('../pages/jadwal_diklat/ujian/UjianWrapper').then(({ UjianWrapper }) => ({ default: UjianWrapper })))

// const Dashboard = lazy(() => import('../pages/lms/dashboard/Dashboard').then(({ Dashboard }) => ({ default: Dashboard })))
// const Report = lazy(() => import('../pages/lms/report/Report').then(({ Report }) => ({ default: Report })))
// const MyCourses = lazy(() => import('../pages/lms/course/my_courses/MyCourses').then(({ MyCourses }) => ({ default: MyCourses })))
// const CourseForm = lazy(() => import('../pages/lms/course/create/CourseForm').then(({ CourseForm }) => ({ default: CourseForm })))
// const CourseMain = lazy(() => import('../pages/lms/course/CourseMainTemp').then(({ CourseMain }) => ({ default: CourseMain })))
// const CourseCategory = lazy(() => import('../pages/lms/course/CourseCategoryTemp').then(({ CourseCategory }) => ({ default: CourseCategory })))
// const CourseMain = lazy(() => import('../pages/lms/course/CourseMain').then(({ CourseMain }) => ({ default: CourseMain })))
// const CourseCategory = lazy(() => import('../pages/lms/course/CourseCategory').then(({ CourseCategory }) => ({ default: CourseCategory })))
// const CourseOverview = lazy(() => import('../pages/lms/course/overview/CourseOverviewTemp').then(({ CourseOverview }) => ({ default: CourseOverview })))
// const CourseOverview = lazy(() => import('../pages/lms/course/overview/CourseOverview').then(({ CourseOverview }) => ({ default: CourseOverview })))
// const CourseDetail = lazy(() => import('../pages/lms/course/detail/CourseDetailTemp').then(({ CourseDetail }) => ({ default: CourseDetail })))
// const CourseDetail = lazy(() => import('../pages/lms/course/detail/CourseDetail').then(({ CourseDetail }) => ({ default: CourseDetail })))

const routes = [
  { path: '/beranda', key: 'beranda', exact: false, name: 'Beranda', element: <Beranda /> },
  { path: '/diklat', key: 'diklat', exact: false, name: 'Diklat', element: <Diklat /> },
  { path: '/diklat/detail', key: 'diklat detail', exact: false, name: 'DiklatDetail', element: <DiklatDetail /> },
  { path: '/agenda', key: 'agenda', exact: false, name: 'Agenda', element: <Agenda /> },
  { path: '/pengetahuan', key: 'pengetahuan', exact: false, name: 'Pengetahuan', element: <Pengetahuan /> },
  { path: '/forum', key: 'forum', exact: false, name: 'Forum', element: <Forum /> },
  { path: '/profil', key: 'profil', exact: false, name: 'Profil', element: <Profil /> },
  { path: '/jadwal-diklat', key: 'jadwal diklat', exact: false, name: 'JadwalDiklat', element: <JadwalDiklat /> },
  { path: '/aktivitas-materi', key: 'aktivitas materi', exact: false, name: 'AktivitasMateri', element: <AktivitasMateri /> },
  { path: '/aktivitas-ujian', key: 'aktivitas ujian', exact: false, name: 'AktivitasUjian', element: <AktivitasUjian /> },
  { path: '/ujian', key: 'ujian', exact: false, name: 'Ujian', element: <UjianWrapper /> },
  //LMS
  // { path: '/lms/dashboard', key: 'lms_dashboard', exact: true, name: 'Dashboard', element: <Dashboard /> },
  // { path: '/lms/report', key: 'lms_report', exact: true, name: 'Report', element: <Report /> },
  // { path: '/lms/my-courses', key: 'my courses', exact: true, name: 'My Courses', element: <MyCourses /> },
  // { path: '/lms/courses', key: 'courses', exact: true, name: 'Courses', element: <CourseMain /> },
  // { path: '/lms/courses/:id', key: 'course_category', exact: true, name: 'Course Category', element: <CourseCategory /> },
  // { path: '/lms/courses/:id/create', key: 'create_course', exact: true, name: 'Create Course', element: <CourseForm /> },
  // { path: '/lms/course/:id', key: 'course_overview', exact: true, name: 'Course Overview', element: <CourseOverview /> },
  // { path: '/lms/course/:id/:id', key: 'course_detail', exact: true, name: 'Course Detail', element: <CourseDetail /> },
];

export default routes;
