import { useState } from "react";
import { useForm } from 'react-hook-form';
// import { useNavigate } from "react-router-dom";
import { login } from "../../store/AuthCRUD";
import { useAuth } from "../../utils/Auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toAbsoluteUrl } from "../../helpers";
import { SlideShow } from "../../components/slider/SlideShow";

export const FormLogin = ( props:any ) => {
  // const navigate = useNavigate();
  const { onLogin } = useAuth();
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = handleSubmit((data)  => {
    setFormDisabled(true)

    login(data.userid, data.password)
      .then((res:any) => {
        toast.success("Login Sukses !", {position: 'top-right'});
        let { user, token, role, divisi } = res.data.data
        role.selected = true
        if ((role?.nama).toLowerCase().includes('admin')) {
          role.selected = false
        }
        onLogin({ user, token, role, divisi });
        setFormDisabled(false)
      })
      .catch(err => {
        toast.error("Login Gagal !", {position: 'top-right'});
        console.log(err)
        setFormDisabled(false)
    })
  });

  return (
    <div>
      <div>
        <img
          alt='header-img'
          style={{paddingBottom: '2px'}}
          // className='h-60px logo'
          src={window.innerWidth > 1024 ? toAbsoluteUrl('/media/images/logo3.png') : toAbsoluteUrl('/media/images/logo4.png')}
        />
        <SlideShow />
      </div>
      <div className="mx-3 my-3">
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <form onSubmit={onSubmit}>
              <div className="form-group pb-3">
                <input className={"form-control"} {...register("userid", { required: true })} readOnly={formDisabled} placeholder="NIPP Lengkap" />
                {errors?.userid && <span className="text-danger">* This field is required</span>}
                {/* {props.error && <span className="text-danger">{props.errors.userid}</span>} */}
              </div>
              <div className="form-group pb-3">
                <input type="password" className={"form-control"} {...register("password", { required: true })} readOnly={formDisabled} placeholder="Password" />
                {errors?.password && <span className="text-danger">* This field is required</span>}
              </div>
              <div>
                <button disabled={formDisabled} className="btn btn-success w-100" type="submit">
                  {formDisabled && <i className="fas fa-spinner fa-pulse"></i>} Sign in   
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
