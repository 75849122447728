// import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Navbar } from './Navbar';
import { useAuth } from '../utils/Auth';
import { PilihRole } from '../pages/auth/PilihRole';
import { NavbarLms } from './NavbarLms';

const MasterLayout = () => {
  const location = useLocation();
  const { role } = useAuth();
  const {pathname} = location
  const arr_path = pathname.split('/')
  const path_ujian = pathname.includes("/ujian/")
  const path_lms = pathname.includes("lms")
  const course_learn_page = pathname.includes('/lms/course/') && arr_path[arr_path.length-1] === 'learn'
  // console.log('console on master layout')

  return (
    <div>
      {!role.selected ?
          <PilihRole /> :
          <>
            {!(path_ujian || course_learn_page) && (
              path_lms ?
                <NavbarLms /> :
                <Navbar />
            )}
            <div className={course_learn_page ? '' : path_lms ? 'bodyContentLms' : 'bodyContent'}>
              <Outlet />
            </div>
          </>
      }
    </div>
  );
}

export default MasterLayout;
